'use server';

import { getRequestEvent } from 'solid-js/web';
import {
  CreateProductSet_DYO_OG7_Params,
  CreateProductSetResponse,
  createProductSet_DYO_OG7,
} from './shopify/createProductSetSizes.js';
/* } from './shopify/createProductSet.js'; */
import {
  PublishProductParams,
  PublishProductResponse,
  publishProductWithMedia_DYO_OG7,
} from './shopify/publishProductPromotion.js';
import { CfPagesEnv } from '~/global.js';
import { Logger, LogLevel } from '@repo/logger';
import { getCookie, setCookie, getEvent } from 'vinxi/http';
import { type } from '../../../../packages/ui/src/components/solidui/badge';
import { getOrders, OrdersResponse, Variables } from './shopify/getOrders.js';

const IMAGES_SVC: string = 'https://images.wrsr.io/v1';

const logger = new Logger('server', { minLevel: LogLevel.DEBUG });

export interface ServerResponse<T> {
  id: string;
  result: ServerResposneSuccess<T> | ServerResponseError;
}

export interface ServerResposneSuccess<T> {
  type: 'success';
  value: T;
}

export interface ServerResponseError {
  type: 'error';
  error: string;
}

export interface Identity {
  guid: string;
  hash: string;
  short: string;
}

export async function sha256Hash(message: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
  // return crypto.createHash('sha256').update(data).digest('hex');
}

export function shortHash(hash: string): string {
  return hash.substring(0, 8).toUpperCase();
}

export async function generateIdentity(): Promise<Identity> {
  const guid = crypto.randomUUID();
  const hash = await sha256Hash(guid);
  const short = shortHash(hash);

  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  logger.info(`GUID: ${guid}`);
  logger.info(`SHA-256 Hash: ${hash}`);
  logger.info(`Short Hash (8 chars, uppercase): ${short}`);
  return {
    guid,
    hash,
    short,
  };
}

// Mock implementation for local development
async function createSessionFallback(): Promise<SessionServiceCreateResponse> {
  const response: SessionServiceCreateResponse = {
    session_id: `mock-${crypto.randomUUID()}`,
    created: new Date().toISOString(),
    mock: true,
  };
  return response;
}

export async function getSessionId(): Promise<string | undefined> {
  // return undefined;
  // if (isServer) {
  // Lazy load the vinxi/http module
  // const { getCookie } = await import('vinxi/http');
  // const event: RequestEvent = getRequestEvent();
  const event = getEvent();
  return getCookie(event, 'session_id');
  // getCookie('session_id');
  // } else {
  //   return document.cookie.match(/session_id=([^;]+)/)?.[1];
  // }
}

interface UserSession {
  auth?: string;
  created: string;
  isMock?: boolean;
}

export async function ensureSession(): Promise<UserSession | undefined> {
  // return undefined;
  const sessionId = await getSessionId();

  if (!sessionId) {
    const response = await createSession({ id: crypto.randomUUID() });
    if (response.result.type === 'success') {
      const { session } = response.result.value;
      return {
        // id: session.session_id,
        auth: 'user',
        created: session.created,
        isMock: response.result.value.mock,
      };
    }
    return undefined;
  }

  return {
    // id: sessionId,
    auth: 'user',
    created: new Date().toISOString(), // We don't have created time for existing sessions
  };
}

export async function serverLogout(): Promise<void> {
  logger.info('serverLogout');
  // Remove the session cookie
  // setCookie('session_id', '', {
  //   path: '/',
  //   maxAge: 0,
  // });
}

export async function serverLogin() {
  logger.info('serverLogin');
  const response = await createSession({
    id: crypto.randomUUID(),
  });
  return response;

  // if (response.result.type === 'error') {
  //   throw new Error(response.result.error);
  // }

  // const { session: newSession } = response.result.value;

  // // Set cookie for persistence
  // setCookie('session_id', newSession.session_id, {
  //   path: '/',
  //   maxAge: 60 * 60 * 24, // 24 hours
  //   secure: true,
  //   httpOnly: true,
  //   sameSite: 'strict',
  // });
}

export interface SessionServiceCreateResponse {
  session_id: string;
  created: string;
  mock?: boolean;
}

export interface CreateSessionRequest {
  id: string;
  data?: ArrayBuffer;
}

export interface CreateSessionResponse {
  session: SessionServiceCreateResponse;
  mock: boolean;
}

async function safeCreateSession(
  request: CreateSessionRequest,
): Promise<SessionServiceCreateResponse> {
  const env = await getEnv();
  if (typeof env.SESSIONS_SERVICE === 'undefined') {
    logger.warn(
      'External session service not available, using mock implementation',
    );
    return createSessionFallback();
  } else {
    const response = await env.SESSIONS_SERVICE.fetch('https://internal/v1', {
      method: 'POST',
      body: request.data,
    });
    if (!response.ok) {
      logger.error('Failed to create session', { status: response.status });
      return createSessionFallback();
    }
    const sessionResult: SessionServiceCreateResponse = await response.json();
    return sessionResult;
  }
}

export async function createSession(
  request: CreateSessionRequest,
): Promise<ServerResponse<CreateSessionResponse>> {
  logger.info('createSession', { request });
  try {
    const sessionResult = await safeCreateSession(request);

    logger.info('createSession - Service Result', { sessionResult });

    // const session = await sessionResult.json<SessionServiceCreateResponse>();
    // return session.session_id;
    const result = {
      session: {
        session_id: sessionResult.session_id,
        created: sessionResult.created,
      },
      mock: sessionResult.mock ?? false,
    };
    logger.info('createSession - Parsed Result', {
      session_id: result.session.session_id,
      created: result.session.created,
    });

    // Set cookie for persistence
    // setCookie('session_id', sessionResult.session_id, {
    //   path: '/',
    //   maxAge: 60 * 60 * 24, // 24 hours
    //   secure: true,
    //   httpOnly: true,
    //   sameSite: 'strict',
    // });
    const event = getEvent();
    setCookie(event, 'session_id', sessionResult.session_id, {
      path: '/',
      maxAge: 60 * 60 * 24, // 24 hours
      secure: true,
      httpOnly: true,
      sameSite: 'strict',
    });
    return { id: request.id, result: { type: 'success', value: result } };
  } catch (error) {
    logger.error('createSession', { error });
    return {
      id: request.id,
      result: { type: 'error', error: 'Failed to create session' },
    };
  }
}

export interface CreateBandFromImageDataRequest {
  id: string;
  title: string;
  description: string;
  email: string;
  phone?: string;
  filename?: string;
  mimetype?: string;
  data: ArrayBuffer;
}

export interface CreateBandFromImageDataResponse {
  upload: ImageUploadResponse;
  product: CreateProductSetResponse;
  publish: PublishProductResponse;
}

export async function createBandFromImageData(
  request: CreateBandFromImageDataRequest,
): Promise<ServerResponse<CreateBandFromImageDataResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    logger.info('createBandFromImageData', { request });

    const { id, title, description, filename, mimetype, data } = request;
    logger.info('createBandFromImageData', { id, len: data.byteLength });

    const uploadResult = await uploadImage(id, {
      data,
      mimetype: mimetype ?? 'image/jpeg',
      filename: filename ?? `band-${id}.jpg`,
    });
    if (uploadResult.result.type !== 'success')
      return { id, result: { type: 'error', error: 'Failed to upload image' } };

    logger.info('uploaded image', { uploadResult });
    const createBandResult = await createBandFromImageId({
      id,
      title,
      description,
      imageName: uploadResult.result.value.image.filename, //.id,
    });
    logger.info('createBandFromImageId', { createBandResult });
    if (createBandResult.result.type !== 'success')
      return { id, result: { type: 'error', error: 'Failed to create band' } };

    const payload = {
      upload: uploadResult.result.value,
      product: createBandResult.result.value.product,
      publish: createBandResult.result.value.publish,
    };
    const sessionId = await getSessionId();
    const metaData = {
      type: 'createBandFromImageData',
      sessionId: sessionId,
      payload,
      inputs: request,
      uploadResult,
      createBandResult,
    };
    const env = await getEnv();
    const r2obj = await env.WRSR_IO_DESIGNER_PRODUCTS.put(
      id,
      JSON.stringify(metaData),
    );
    logger.info('saved product design link', {
      key: r2obj?.key,
      type: metaData.type,
      sessionId: metaData.sessionId,
      payload: metaData.payload,
      inputs: metaData.inputs,
      uploadResult: metaData.uploadResult,
      createBandResult: metaData.createBandResult,
    });
    return {
      id,
      result: {
        type: 'success',
        value: payload,
      },
    };
  } catch (error) {
    logger.error('createBandFromImageId', { error });
    return {
      id: request.id,
      result: { type: 'error', error: 'Failed to create band' },
    };
  }
}

export interface CreateBandFromImageIdRequest {
  id: string;
  title: string;
  description: string;
  imageName: string;
}

export interface CreateBandFromImageIdResponse {
  product: CreateProductSetResponse;
  publish: PublishProductResponse;
}

export async function createBandFromImageId(
  request: CreateBandFromImageIdRequest,
): Promise<ServerResponse<CreateBandFromImageIdResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    const { id, title, description, imageName } = request;

    // TODO(pbirch): Fetch image details from service and add to response payload

    const identity = await generateIdentity();
    const createProductResult = await createProduct(id, {
      identity,
      title,
      description,
      imageName,
    });
    logger.info('created product', { createProductResult });
    if (createProductResult.result.type !== 'success')
      return {
        id,
        result: { type: 'error', error: 'Failed to create product' },
      };

    const publishProductResult = await publishProduct(id, {
      productId: createProductResult.result.value.productSet.product.id,
      publicationId: 'gid://shopify/Publication/108770754650',
    });
    logger.info('published product', { publishProductResult });
    if (publishProductResult.result.type !== 'success')
      return {
        id,
        result: { type: 'error', error: 'Failed to publish product' },
      };

    // TODO(pbirch): poll the shopify API to detect image errors and notify for ready to view status instead of arbitrary delay

    return {
      id,
      result: {
        type: 'success',
        value: {
          product: createProductResult.result.value,
          publish: publishProductResult.result.value,
        },
      },
    };
  } catch (error) {
    logger.error('createBandFromImageId', { error });
    return {
      id: request.id,
      result: { type: 'error', error: 'Failed to create band' },
    };
  }
}

// Constants
const DEFAULT_MIME_TYPE = 'image/jpeg';

// Type definitions
export interface ImageUploadRequest {
  data: ArrayBuffer;
  mimetype?: string;
  filename?: string;
}

export interface ImageUploadResult {
  id: string;
  url: URL;
  filename: string;
  mimetype: string;
  meta: unknown[];
  required_signed: boolean;
  uploaded: string;
  checksum_type: string;
  checksum: number[];
  success: boolean;
}

export type ImageUploadResponse = {
  image: ImageUploadResult;
  url: URL;
};

/**
 * Uploads an image to the image service
 * @param id Request identifier
 * @param request Image upload request containing data and metadata
 * @returns Server response with upload result or error
 */
export async function uploadImage(
  id: string,
  request: ImageUploadRequest,
): Promise<ServerResponse<ImageUploadResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  logger.info('uploadImage', { id, request });
  try {
    // Validate input
    if (!request.data || request.data.byteLength === 0) {
      return {
        id,
        result: {
          type: 'error',
          error: 'Invalid or empty image data',
        },
      };
    }

    // Prepare headers with proper typing
    const headers = new Headers({
      'Content-Type': request.mimetype || DEFAULT_MIME_TYPE,
    });

    logger.info('uploadImage - post image', { len: request.data.byteLength });
    // Make the request
    const response = await fetch(IMAGES_SVC, {
      method: 'POST',
      headers,
      body: request.data,
    });
    logger.info('uploadImage - response', { response });

    // Handle non-ok responses
    if (!response.ok) {
      const errorText = await response.text();
      return {
        id,
        result: {
          type: 'error',
          error: `Upload failed: ${response.status} - ${errorText}`,
        },
      };
    }

    // Parse and validate response
    const result = (await response.json()) as ImageUploadResult;

    logger.info('uploadImage - result', { result });
    // Validate required fields
    if (!result.id || !result.success) {
      return {
        id,
        result: {
          type: 'error',
          error: 'Invalid response from image service',
        },
      };
    }

    // Add the URL to the result
    result.url = new URL(`${IMAGES_SVC}/${result.id}`);

    return {
      id,
      result: {
        type: 'success',
        value: {
          image: result,
          url: result.url,
        },
      },
    };
  } catch (error) {
    logger.error('uploadImage', { error });
    return {
      id,
      result: {
        type: 'error',
        error: error instanceof Error ? error.message : 'Unknown upload error',
      },
    };
  }
}

export async function getEnv(): Promise<CfPagesEnv> {
  const event = getRequestEvent();
  // if (event === undefined) return await import.meta.env as any as CfPagesEnv;
  if (
    event != undefined &&
    event.nativeEvent.context.cloudflare &&
    event.nativeEvent.context.cloudflare.env
  ) {
    return event.nativeEvent.context.cloudflare.env;
  }
  return (await import.meta.env) as any as CfPagesEnv;
}

export interface ImageDescriptor {
  id: string;
  filename: string;
  mimetype: string;
  checksum: Array<number>;
}

export interface ShopifyProductDescriptor {
  title: string;
  description: string;
}

export type PublishProductPayload = {
  image: ImageDescriptor;
  product: ShopifyProductDescriptor;
};

export type PostProductResponse = {
  // identity: Identity,
  handle: string;
  url: URL;
  mediaCount: number;
  /**
   * PROCESSING status means the image isn't ready to display
   * TODO(pbirch): poll shopify to detect image errors and notify for ready to view status instead of arbitrary delay
   */
  mediaStatus: Array<string>;
};

export async function createProduct(
  id: string,
  params: CreateProductSet_DYO_OG7_Params,
): Promise<ServerResponse<CreateProductSetResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    logger.info('createProduct', { id, params });
    // const client = await buildShopifyClient();
    // if (client === undefined)
    // return { id, result: { type: 'error', error: 'Invalid Shopify client' } };
    const value: CreateProductSetResponse | void =
      await createProductSet_DYO_OG7(
        // client,
        params,
      ).catch((err) => {
        logger.error('failed to create product', err);
      });
    if (!value)
      return {
        id,
        result: { type: 'error', error: JSON.stringify(Response.error()) },
      };
    logger.info('createProduct', { id, value });
    return { id, result: { type: 'success', value } };
  } catch (error) {
    logger.error('createProduct', { error });
    return { id, result: { type: 'error', error: 'Failed to create product' } };
  }
}

export async function publishProduct(
  id: string,
  params: PublishProductParams,
): Promise<ServerResponse<PublishProductResponse>> {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    logger.info('publishProduct', { id, params });
    // const client = await buildShopifyClient();
    // if (client === undefined)
    //   return { id, result: { type: 'error', error: 'Invalid Shopify client' } };
    const value: PublishProductResponse | void =
      await publishProductWithMedia_DYO_OG7(
        // client,
        params,
      ).catch((err) => {
        logger.error('failed to publish product', { err });
      });
    if (!value)
      return {
        id,
        result: { type: 'error', error: JSON.stringify(Response.error()) },
      };
    logger.info('publish product complete');
    return { id, result: { type: 'success', value } };
  } catch (error) {
    logger.error('publishProduct', { error });
    return {
      id,
      result: { type: 'error', error: 'Failed to publish product' },
    };
  }
}

export async function getOrdersServer({ first, cursor }: Variables) {
  const logger = new Logger('server', { minLevel: LogLevel.DEBUG });
  try {
    // const client = await buildShopifyClient();
    // if (client === undefined)
    //   return { id, result: { type: 'error', error: 'Invalid Shopify client' } };
    const value: OrdersResponse | void = await getOrders({
      first,
      cursor,
    }).catch((err) => {
      logger.error('failed to get Orders', { err });
    });
    if (!value)
      return {
        result: { type: 'error', error: JSON.stringify(Response.error()) },
      };
    logger.info('get orders complete');
    return { result: { type: 'success', value } };
  } catch (error) {
    logger.error('publishProduct', { error });
    return {
      result: { type: 'error', error: 'failed to get Orders' },
    };
  }
}
